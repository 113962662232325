/* ProfilePage.css */
.profile-page {
  max-width: 600px;
  margin: 2em auto;
  padding: 1em;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: Arial, sans-serif;
}

.profile-page h2 {
  text-align: center;
  margin-bottom: 1em;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.form-group input {
  padding: 0.5em;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 0.7em;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.submit-button:hover {
  background-color: #0056b3;
}

.message {
  text-align: center;
  color: green;
}

/* Estilos para el contenedor de la tabla */
.table-container {
  margin: 1.5em 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: #fff;
}

/* Estilos para la tabla */
.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 0.75em;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Encabezados con el color primario del sitio */
.table-container th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

/* Efecto hover en las filas */
.table-container tr:hover {
  background-color: #f1f1f1;
}

/* Estilo para la fila colapsada (detalles del requester) */
.collapse-content {
  background-color: #f9f9f9;
  padding: 10px;
  border-top: 1px solid #ddd;
}
