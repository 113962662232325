/* Base Styles */
body {
  font-family: 'Cardo', serif;
  background-color: #f0f4f8;
  color: #303030;
  margin: 0;
  padding: 0;
}

.card-list-container {
  gap: 20px; /* Espaciado entre las listas */
  max-width: 1200px; /* Ancho máximo para el contenedor */
  margin: 0 auto; /* Centrado horizontal */
  padding: 20px; /* Espaciado interno */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .card-list-container {
    grid-template-columns: 1fr; /* Las listas se apilan en pantallas pequeñas */
    gap: 15px;
  }
}
.container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  overflow: hidden; /* Ensures nothing spills outside the container */
}

/* Header and Titles */
.header-with-title, .main-title {
  color: #4a5278;
}

.banner {
  display: flex;
  justify-content: center;
  overflow: hidden; /* Prevent banner from causing overflow */
}

.banner img {
  max-height: 250px;
  width: 100%; /* Make image responsive */
  object-fit: cover; /* Cover the area without stretching */
  object-position: center; /* Center the focus of the image */
}

/* Style for the suggestions container */
.suggestions-container {
  background-color: #f7f7f7; /* Light gray background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin-top: 10px; /* Space above the container */
  padding: 10px; /* Padding inside the container */
  width: 100%; /* Full width to match the search input */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
  display: flex; /* Use flex to manage children */
  flex-direction: column; /* Stack suggestions vertically */
  gap: 5px; /* Space between individual suggestions */
}

.suggestion-item:hover {
  background-color: #cccccc; /* Darker on hover to indicate selection */
}

.search-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.search-input, button, .buttonStyle {
  border: 2px solid #4a5278;
  background-color: #e8eaf6;
  color: #5c6bc0;
  padding: 10px 15px;
  border-radius: 20px;
  outline: none;
}

.buttonStyle {
  background-color: #007bff; /* Ajusta este color según tu tema */
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px; /* Espaciado entre botones */
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.buttonStyle:hover {
  background-color: #0056b3; /* Color al pasar el mouse */
}

button:hover, .buttonStyle:hover {
  background-color: #c5cae9;
  color: #333;
}

.provider-checkboxes label {
  margin-right: 15px;
}

.card-list {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-item {
  display: inline-block;
  margin: 10px;
  text-align: center;
}

.card-image {
  width: 100px;
  height: 140px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@keyframes tapAndShrink {
  0% {
    transform: rotate(0deg) scale(1);
  }
  30% {
    transform: rotate(15deg) scale(0.85);
  }
  60% {
    transform: rotate(-10deg) scale(0.9);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

.loader-image {
  width: 100px;
  height: 140px;
  animation: tapAndShrink 1.5s infinite ease-in-out;
}

@media (max-width: 768px) {
  .search-container {
    flex-direction: column;
  }

  .buttonStyle, button {
    width: 100%;
    margin-top: 10px;
  }

  .card-list {
    overflow-x: auto;
  }

  .card-item {
    width: auto;
  }
  
  .search-container {
    flex-direction: column; /* Stack elements on smaller screens */
    align-items: flex-start; /* Align to the start of the container */
  }

  .buttonStyle {
    margin-top: 10px; /* Add some space above the button on smaller screens */
    width: 100%; /* Full width button on smaller screens */
  }
  .scroll-buttons {
    display: flex; /* Show on mobile */
  }

  .card-list {
    /* Ensure there's space for buttons */
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner img {
    height: auto; /* Adjust the height as needed for mobile devices */
  }
}

.search-container {
  margin-top: 30px; /* Añade espacio encima de la sección de búsqueda */
}

.toggle-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.toggle-button:focus {
  outline: none;
}

.translate-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #f0f0f0; /* Fondo del traductor */
  padding: 0 20px; /* Padding lateral */
  border-radius: 8px;
}

.translate-container.open {
  max-height: 500px; /* Ajusta este valor según el contenido */
  padding: 20px; /* Agrega padding cuando está abierto */
  transition: max-height 0.5s ease-in;
}

.translate-container h2 {
  text-align: center;
  color: #333;
}

.translate-container p {
  text-align: center;
  color: #555;
}

.translate-container .search-input {
  width: 60%;
  max-width: 400px;
  min-width: 250px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.translate-container .error-message {
  color: red;
  margin-top: 10px;
}

.search-container {
  margin-top: 30px;
}

.translate-toggle .buttonStyle {
  margin-bottom: 20px; /* Ajusta el espacio debajo del botón */
}

.card-list-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  background-color: #f9f9f9; /* Fondo claro */
  border: 1px solid #ddd; /* Borde suave */
  border-radius: 8px;
}

.card-list-title {
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}

.scryfall-card {
  text-align: center;
}

.scryfall-card p {
  margin: 5px 0;
  font-size: 18px;
}

.scryfall-card strong {
  font-size: 20px;
}

.translation-results ul {
  list-style-type: none;
  padding: 0;
}

.translation-results li {
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  margin: 5px auto;
  width: 80%;
  border-radius: 5px;
  text-align: center;
}

.translation-results li:hover {
  background-color: #e0e0e0;
}

.dolar-container {
  text-align: center; /* Centra la imagen */
  margin-top: 20px; /* Espacio arriba */
  padding: 10px;
  background-color: #f9f9f9; /* Fondo claro para destacar */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Sombra para profundidad */
}

.dolar-image {
  width: 150px; /* Ajusta este valor según tus necesidades */
  height: auto; /* Mantiene la proporción */
  border-radius: 8px; /* Bordes redondeados */
}

/* Vendor Grid Styles */
.vendor-selection {
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 2rem 0;
  display: flex!important;
  justify-content: center;
  flex-wrap: wrap;
}

.vendor-grid {
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1.5rem;
}

/* Chip Styling */
.chip {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  transition: all 0.2s ease;
  cursor: pointer;
}

.chip-primary {
  background: #4a5278;
  border-color: #4a5278;
  color: white;
}

.chip:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.vendor-logo {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  margin-right: 0.8rem;
  object-fit: contain;
}

/* Special Chips */
.dollar-button {
  grid-column: 1 / -1;
  background: #e3f2fd;
  justify-content: center;
  font-weight: 600;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vendor-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .chip {
    padding: 0.7rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .vendor-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .dolar-container {
    margin-top: 15px;
  }

  .dolar-image {
    width: 100px; /* Tamaño más pequeño para dispositivos móviles */
  }
}
