/* CardsByCollection.css */
.collections-page {
  margin: 2rem;
}

.collection-block {
  margin-bottom: 2rem;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 8px;
}

.collection-layout {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.collection-cards-list {
  width: 250px;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem;
}

.collection-cards-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.card-list-item:hover {
  background-color: #f5f5f5;
}

.unavailable-overlay {
  color: #ff4444;
  font-size: 0.9em;
}

.collection-binder-image {
  flex: 1;
  min-height: 400px;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
}

.image-wrapper {
  position: relative;
  height: 100%;
}

.big-binder-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.highlight-box {
  position: absolute;
  border: 2px solid #ff0000;
  pointer-events: none;
}

.image-pagination-controls {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

/* Fullscreen styles */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.fullscreen-controls-top {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 10000;
}

.fullscreen-image-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: pan-y;
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.card-list-fullscreen {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  height: 50vh;
  background: white;
  transition: bottom 0.3s ease;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  z-index: 10001;
}

.card-list-fullscreen.visible {
  bottom: 0;
}

.card-list-content {
  max-height: 45vh;
  overflow-y: auto;
}

.fullscreen-pagination {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: white;
  padding: 8px;
  background: rgba(0, 0, 0, 0.5);
}

/* Mobile specific styles */
.mobile-layout {
  position: relative;
}

.mobile-binder-image {
  position: relative;
  touch-action: none;
}

.floating-cardlist-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.mobile-cardlist-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60vh;
  background: white;
  z-index: 999;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.selected-sets-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 8px 0;
}

.selected-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}