/* src/pages/StatisticsPage.css */
.statistics-page {
  padding: 2rem;
  background: #0f172a;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.glass-card {
  background: rgba(30, 41, 59, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  transition: transform 0.2s ease;
}

.glass-card:hover {
  transform: translateY(-5px);
}

.metric-title {
  font-size: 1.1rem;
  color: #94a3b8;
  margin-bottom: 0.5rem;
}

.metric-value {
  font-size: 2rem;
  font-weight: 700;
  color: #3b82f6;
}

.chart-container {
  height: 300px;
  margin-top: 1rem;
}

.data-table {
  --table-accent: rgba(59, 130, 246, 0.1);
  --table-hover: rgba(59, 130, 246, 0.05);
  border-radius: 12px;
  overflow: hidden;
}

.data-table thead {
  background: rgba(30, 41, 59, 0.9);
}

.data-table th {
  font-weight: 600;
  color: #3b82f6 !important;
}

.data-table td {
  color: #cbd5e1 !important;
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.75rem;
  background: rgba(59, 130, 246, 0.15);
  color: #3b82f6;
}