.collections-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.collection-block {
  margin-bottom: 40px;
}

.collection-image-wrapper {
  text-align: center;
  margin: 20px 0;
}

.collection-image {
  max-width: 100%;
  height: auto;
}

.image-navigation {
  margin-top: 10px;
}

.cards-list {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
}
