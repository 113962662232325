/* Basic reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

/* Push content below the navbar */
.App {
  margin-top: 60px;
}

/* NAVBAR */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #2c3e50;
  z-index: 1000; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

/* Optional brand link on the far left */
.navbar-left-desktop {
  display: flex;
  align-items: center;
}

.brand-link {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  margin-right: 1rem;
}

/* Right side: user info or login */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Toggles for mobile */
.navbar-toggles {
  display: none; /* shown on mobile only */
  gap: 0.5rem;
}

/* Common link style for the top nav and sidebars */
.nav-link {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 16px;
}

.nav-link:hover {
  color: #bdc3c7;
}

/* Welcome text & logout button */
.welcome-text {
  color: #ecf0f1;
}

.logout-button {
  background-color: #e74c3c;
  border: none;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.logout-button:hover {
  background-color: #c0392b;
}

/* LEFT SIDEBAR (menu) */
.sidebar-menu {
  position: fixed;
  top: 60px; /* below navbar */
  left: -250px;
  width: 250px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0,0,0,0.2);
  z-index: 999;
  padding: 1rem;
  transition: left 0.3s ease-in-out;
}

.sidebar-menu.menu-open {
  left: 0;
}

.menu-links h4 {
  margin: 0.5rem 0;
  color: #2c3e50;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
}

.menu-links a {
  display: block;
  margin: 0.25rem 0;
  color: #2c3e50;
  text-decoration: none;
  font-size: 16px;
}

.menu-links a:hover {
  color: #007bff;
}

/* RIGHT SIDEBAR (saved list) */
.sidebar-saved {
  position: fixed;
  top: 60px; /* below navbar */
  right: -250px;
  width: 250px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0,0,0,0.2);
  z-index: 999;
  padding: 1rem;
  transition: right 0.3s ease-in-out;
}

.sidebar-saved h3 {
  margin-top: 0;
}

.sidebar-saved.saved-open {
  right: 0;
}

/* MOBILE: up to 768px */
@media (max-width: 768px) {
  /* Hide brand or reduce it if you want */
  .navbar-left-desktop {
    display: none;
  }

  /* Show the toggle buttons on mobile */
  .navbar-toggles {
    display: flex;
  }

  /* The main nav links (brand) can be placed differently or hidden. Up to you. */
  .navbar-right {
    margin-left: auto;
  }

  .toggle-button {
    font-size: 18px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
  }
}

/* DESKTOP: from 769px up 
   Keep both sidebars open by default. 
   Also give the .App a margin on left and right so content isn't covered. */
@media (min-width: 769px) {
  .sidebar-menu {
    left: 0; /* always open */
  }
  .sidebar-saved {
    right: 0; /* always open */
  }
  /* Give main content some margin so it doesn't hide behind sidebars */
  .App {
    margin-left: 250px;  /* left sidebar width */
    margin-right: 250px; /* right sidebar width */
  }
  /* We don't need the toggles on desktop. */
  .navbar-toggles {
    display: none;
  }
}
