/* src/pages/MyCollectionsList.css */
.my-collections-list {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.collection {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
}

.collection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collection-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.collection-folder {
  display: flex;
  gap: 10px;
}

.collection-folder-img {
  width: 80px;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

.collection-actions {
  margin-top: 10px;
}

.collection-actions button {
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 0.9rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.collection-actions button:hover {
  background-color: #0056b3;
}

.collection-cards {
  margin-top: 15px;
}

.cards-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.card-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.card-item:last-child {
  border-bottom: none;
}

.card-image {
  width: 60px;
  height: 80px;
  margin-right: 15px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.box-info {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  color: #fff;
  font-size: 0.7rem;
  padding: 2px 4px;
  border-top-right-radius: 4px;
}

.card-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-name {
  font-weight: bold;
  font-size: 1rem;
}

.card-status {
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
}

.card-actions {
  display: flex;
  gap: 10px;
}

.card-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #007bff;
  transition: color 0.2s;
}

.card-actions button:hover {
  color: #0056b3;
}

.collection-edit,
.card-edit {
  margin-bottom: 10px;
}
.collection-edit label,
.card-edit label {
  display: block;
  margin-bottom: 5px;
}

.collection-edit input,
.card-edit input,
.card-edit select {
  padding: 5px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 8px;
}

.card-edit-actions button {
  margin-right: 5px;
  padding: 3px 6px;
  font-size: 0.9rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.modal-img {
  width: 100%;
  height: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #d9534f;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
