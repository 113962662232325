.bulk-search-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.upload-section {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  margin-bottom: 2rem;
}

.drag-drop-zone {
  border: 2px dashed #ddd;
  border-radius: 8px;
  padding: 3rem 1rem;
  text-align: center;
  transition: all 0.3s ease;
  margin-bottom: 2rem;
}

.drag-drop-zone:hover {
  border-color: #2196F3;
  background: #f8f9ff;
}

.upload-icon {
  font-size: 3rem !important;
  color: #666;
  margin-bottom: 1rem;
}

.vendor-selection h3 {
  margin-bottom: 1rem;
  color: #444;
}

.vendor-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 0.8rem;
}

.vendor-logo {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  object-fit: contain;
}

.progress-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.progress-text {
  text-align: center;
  margin-top: 1rem;
  color: #666;
}

.results-section {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.controls {
  display: flex;
  gap: 1rem;
}

.results-grid {
  display: grid;
  gap: 2rem;
}

.card-group {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.offers-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.offer-card {
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s;
}

.offer-card:hover {
  transform: translateY(-2px);
}

.offer-card.exact {
  border-left: 4px solid #4CAF50;
}

.offer-card.partial {
  border-left: 4px solid #FFC107;
}

.offer-main {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.offer-details {
  display: flex;
  flex-direction: column;
}

.price {
  font-weight: 600;
  color: #2e7d32;
}

.vendor {
  font-size: 0.9em;
  color: #666;
  text-transform: capitalize;
}

.offer-meta {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.stock {
  font-size: 0.9em;
  color: #666;
}

.warning-icon {
  color: #FFC107;
  font-size: 1.2rem !important;
}

.no-results {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  color: #999;
  text-align: center;
  font-style: italic;
}

@media (max-width: 768px) {
  .vendor-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
  
  .offers-list {
    grid-template-columns: 1fr;
  }
  
  .offer-card {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }
}

.loader-image {
  width: 100px;
  height: 140px;
  animation: tapAndShrink 1.5s infinite ease-in-out;
}


@keyframes tapAndShrink {
  0% {
    transform: rotate(0deg) scale(1);
  }
  30% {
    transform: rotate(15deg) scale(0.85);
  }
  60% {
    transform: rotate(-10deg) scale(0.9);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

