/* Estilos responsivos para CardList */
.cards {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 10px;
  text-align: center;
  justify-content: space-between;
  white-space: normal; /* Permite que los textos se ajusten bien */
}

.card-item {
  flex: 0 0 140px; /* Ajuste de tamaño en móviles */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.card-image-container {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}



.card-list-container {
  gap: 20px; /* Espaciado entre las listas */
  max-width: 1200px; /* Ancho máximo para el contenedor */
  margin: 0 auto; /* Centrado horizontal */
  padding: 20px; /* Espaciado interno */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .card-list-container {
    grid-template-columns: 1fr; /* Las listas se apilan en pantallas pequeñas */
    gap: 15px;
  }
}

/* Mejoras para la sección de búsqueda en móviles */
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  font-size: 1rem;
}

.buttonStyle {
  width: 100%; /* Botón grande para mobile */
  padding: 12px;
  font-size: 1.1rem;
  border-radius: 6px;
}

/* Ajuste de lista de cartas en móviles */
.card-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
  gap: 10px;
  scroll-behavior: smooth;
  white-space: nowrap;
}

.card-item {
  flex: 0 0 180px; /* Más ancho para evitar apariencia de teclas de piano */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: normal; /* Permite que los textos se ajusten bien */
}

.card-image-container {
  width: 100%;
  max-height: 220px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image {
  width: 100%;
  max-height: 180px; /* Aumenta el tamaño de la imagen sin deformar */
  object-fit: contain;
  border-radius: 8px;
}

.card-title {
  font-size: 1rem;
  margin: 5px 0;
}

.card-price {
  font-size: 0.9rem;
  color: #333;
}

.save-button {
  margin-top: 10px;
  font-size: 0.85rem;
  padding: 8px 12px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}

@media (max-width: 480px) {
  .card-item {
    flex: 0 0 160px; /* Ajuste en pantallas más pequeñas */
  }
  .card-title {
    font-size: 0.85rem;
  }
  .card-price {
    font-size: 0.75rem;
  }
}
